import { Link, graphql } from 'gatsby'
import { ModalRoutingContext } from 'gatsby-plugin-modal-routing'
import * as Contentstyle from '../../components/content/content.module.scss'
import * as style from '../../components/album/album.module.scss'
import Content from '../../components/content/content'
import Img from "gatsby-image"
import React from 'react'

const DoseOfDistinctionLP = ( {data} ) => (
  <ModalRoutingContext.Consumer>
    {({ modal, closeTo }) => (
      <div className={style.albumDetails}>
        {modal ? (
          <Link to={closeTo}>
            <svg className={style.albumDetails__close} width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M7.53553 0.464768C7.92606 0.855293 7.92606 1.48846 7.53553 1.87898L5.41421 4.0003L7.53553 6.12162C7.92606 6.51215 7.92606 7.14531 7.53553 7.53584C7.14501 7.92636 6.51184 7.92636 6.12132 7.53584L4 5.41452L1.87868 7.53584C1.48815 7.92636 0.854988 7.92636 0.464463 7.53584C0.0739387 7.14531 0.0739387 6.51215 0.464463 6.12162L2.58578 4.0003L0.464463 1.87898C0.073939 1.48846 0.0739387 0.855293 0.464463 0.464768C0.854988 0.0742437 1.48815 0.0742441 1.87868 0.464768L4 2.58609L6.12132 0.464768C6.51184 0.0742441 7.14501 0.0742437 7.53553 0.464768Z" />
            </svg>
          </Link>
        ) : (
          <div>
            {/* Not sure */}
          </div>
        )}

          <Content extraClass='content--modal'>
            <div className="column column--40">
              <div className="sticky">

                <Img className={style.albumDetails__cover} fluid={data.cover.childImageSharp.fluid} />

                <p className={style.albumDetails__credits}>
                <strong>Executive Producers:</strong> Specyal T & Brian La Roche<br/>
                <strong>Label:</strong> Soul Kiss Ent. / Universal<br/>
                <strong>Recorded At:</strong> Avenue Kode Studios<br/>
                <strong>Production Provided By:</strong> Soul Kiss Ent., Slept Productions, Ted Chin, Tokunbo & Specyal T Productions<br/>
                <strong>Mixed & Mastered By:</strong> Soul Kiss Ent.

                </p>

              <div className={Contentstyle.subtitleLowercase}>Tracks</div>

              <ol className={style.albumDetails__tracks}>
                <li>Shine</li>
                <li>Get Me Thru</li>
                <li>Paralyzed</li>
                <li>Sweat It Up!</li>
                <li>Push Play</li>
                <li>Sippin On</li>
                <li>I Love The Way feat. Catherine Marie</li>
                <li>Salt &amp; Pepper</li>
                <li>Caught Ya Lookin feat. Tokunbo</li>
                <li>Ice Cream Cone</li>
                <li>Waterproof</li>
                <li>Airtight feat. Catherine Marie</li>
                <li>Sweat It Up! (Retro Rock Soul Kiss Remix)</li>
              </ol>

              </div>

            </div>
            <div className="column column--60">
            <span className={Contentstyle.titleLowercase}>Track Synopsis</span>

            <p><strong>Shine</strong><br/>
            A positive message about appreciating your blessings and paying it forward.</p>
            <hr /> 

            <p><strong>Get Me Thru</strong><br/>
            Getting frustrated with changes in a relationship but still acknowledging that your person is always there.</p>
            <hr /> 

            <p><strong>Paralyzed</strong><br/>
            When your fantasy person comes to life but isn’t exactly what you expected.</p>
            <hr /> 

            <p><strong>Sweat It Up!</strong><br/>
            Vibing at an old school house party.</p>
            <hr /> 

            <p><strong>Push Play</strong><br/>
            An ode to old school 90’s Hip-Hop .</p>
            <hr /> 

            <p><strong>Sippin’ On</strong><br/>
            A rendezvous between two strangers with the older one getting awkwardly attached soon afterwards.</p>
            <hr /> 

            <p><strong>I Love The Way</strong><br/>
            Reminiscing about the many years of a loving relationship and celebrating at their wedding.</p>
            <hr /> 

            <p><strong>Salt & Pepper</strong><br/>
            A young couple face the challenges of an inter-racial relationship.</p>
            <hr /> 

            <p><strong>Caught Ya Lookin’</strong><br/>
            A flirty song to T’s potential fans about how she knows they’re checking out her vibe.</p>
            <hr /> 

            <p><strong>Ice Cream Cone</strong><br/>
            An unapologetic proposition with a successful conclusion.</p>
            
            <p><strong>Waterproof</strong><br/>
            A young girl tries to cope with the daily verbal, physical and emotional abuse inflicted on her by her peers.</p>
            
            <p><strong>Airtight</strong><br/>
            Celebrating the S.T.P. brand and T’s signature vibe.</p>
            
            <p><strong>Sweat It Up! (Retro Rock Soul Kiss Remix)</strong><br/>
            Vibing at an old school house party with a retro-rock theme.</p>

              
           </div>

          </Content>

      </div>
    )}
  </ModalRoutingContext.Consumer>
)

export default DoseOfDistinctionLP


export const query = graphql`
query {
  cover: file(relativePath: {eq: "st-store/albums/doseofdistinction.jpg"}) {
      childImageSharp {
        fluid(maxWidth: 800) {
          ...GatsbyImageSharpFluid
       } 
    }
  }
}
`